:root {

  @each $breakpoint, $value in $breakpoints {
    --#{$breakpoint}: #{$value};
  }

  --container-padding: #{$container-padding};
  --container-padding-xl: #{$container-padding-xl};

  --zindex-modal: #{$zindex-modal};

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }
}

@media (prefers-color-scheme: dark) {

  :root {

    @each $color, $value in $dark-theme-colors {
      --#{$color}: #{$value};
    }
  }
}

html {

  &.dark {
    @each $color, $value in $dark-theme-colors {
      --#{$color}: #{$value};
    }
  }
}

body {
  background-color: var(--background);
  @include transition(background-color);
}

.page {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding-top: $page-header-height;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $container-max-width;
  padding: 0 $container-padding;

  @include xl {
    padding: 0 $container-padding-xl;
  }
}

.overlay {
  visibility: hidden;
  @include absolute-stretch(fixed);
  background-color: var(--overlay-background);
  z-index: $zindex-overlay;
  opacity: 0;
  @include transition(all);

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

// Keyframes
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}