.hero {

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__title {
    display: flex;
    max-width: 300px;
    flex-flow: column nowrap;
    gap: 20px;
    opacity: 1;
    @include transition;
    transition-duration: 1s;
    transition-property: opacity, margin-top;

    text-align: center;

    &.hide {
      opacity: 0;
    }

    h1 {
      font-weight: 770;
      font-size: 24px;
      line-height: 115%;
      color: #222222;
  
      @include dark {
        color: #FFFFFF;
      }
    }
  }

  &__image {
    padding-top: 30px;
  }

  @include md {

    &__container {
      width: 560px;
    }

    &__title {
      max-width: none;

      h1 {
        font-size: 36px;
        line-height: 110%;
      }
    }
  }

  @include xl {

    &__container {
      width: 100%;
    }

    &__title {

      h1 {
        font-size: 60px;
      }
    }

    &__image {
      padding-top: 110px;
    }
  }
}

.hero-form {
  $this: &;
  $t1: 1s;
  $gap: 20px;

  --fieldset-gap: 10px;

  padding-top: 40px;
  width: 100%;
  position: relative;

  @include transition(height);
  transition-duration: 1s;
  transition-delay: 1.2s;

  &.open {
    transition-delay: 0s;
  }

  &__close-btn {
    width: 40px;
    height: 40px;
    @include centering;
    display: none;

    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    color: #366AF3;

    &.show {
      display: flex;
      animation: fade-in cubic-bezier(0.215, 0.61, 0.355, 1) .7s;
    }
  }

  &__form {
    position: relative;
    width: 100%;
  }

  &__heading {
    width: 100%;
    position: absolute;
    bottom: calc(100% + $gap);
  }

  &__title,
  &__text {
    overflow: hidden;
  }
  
  &__send-group {
    display: flex;
    flex-flow: column nowrap;
    gap: $gap;
  }

  &__fieldset {
    position: relative;
    height: $input-height;
    justify-content: center;

    transition: height $t1 ease-in;
    transition-delay: 1s;

    #{$this}.open & {
      height: calc($input-height * 3 + var(--fieldset-gap) * 2);
      transition-delay: 0s;
    }
  }

  &__group {
    position: absolute;
    width: 100%;

    &_main {
      position: relative;
      z-index: 1;
    }

    &:first-child {
      top: 0;
    }

    &:last-child {
      bottom: 0;
    }
  }

  &__info {
    display: none;
  }
  
  &__btn {
    width: 100%;
  }

  &__agreement {
    position: absolute;
    top: calc(100% + $gap);
    overflow: hidden;
  }

  &__slideup {
    transform: translateY(100%);
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

    &.show {
      transform: translateY(0);
      transition-duration: 0.3s;
      transition-delay: 1.2s;
    } 
  }

  @include xl {
    --fieldset-gap: 20px;

    width: auto;
    display: flex;
    align-items: center;

    &__send-group {
      flex-direction: row;
    }

    &__fieldset {
      width: 385px;
    }

    &__btn-group {
      display: flex;
      flex-flow: column nowrap;
      position: relative;
      width: min-content;
      overflow: hidden;
    }

    &__info {
      height: calc($input-height * 2 + var(--fieldset-gap));
      position: absolute;
      top: 0;
      z-index: -1;
      @include transition(top);

      display: flex;
      flex-flow: column nowrap;
      gap: 10px;
      padding: 15px 25px;
      background: rgba(54, 106, 243, 0.25);
      border-radius: 5px;

      font-weight: 400;
      font-size: 18px;
      line-height: 165%;
      color: #FFFFFF;
    }

    &__btn {
      margin-top: auto;
      width: fit-content;
    }
  }
}

.section {
  padding-top: 80px;

  &__title {
    margin-bottom: 25px;
    font-weight: 770;
    font-size: 24px;
    line-height: 115%;
    text-align: center;
    color: var(--head-blue-secondary);

    span {
      color: var(--head-blue);
    }
  }

  @include md {

    &__title {
      font-size: 36px;
    }
  }

  @include xl {
    padding-top: 120px;
    
    &__title {
      margin-bottom: 50px;
      font-weight: 600;
      font-size: 48px;
      line-height: 110%;
    }
  }
}

.carousel {
  padding-top: 50px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(50% - 60px);
    background: linear-gradient(90deg, #FFFFFF 16.2%, rgba(255, 255, 255, 0) 100%);
    @include transition(background);
    z-index: 1;

    @include dark {
      background: linear-gradient(90deg, #000633 16.2%, rgba(0, 6, 51, 0) 100%);
    }
  }

  &::after {
    transform: rotate(180deg);
    left: auto;
    right: 0;
  }
  
  &__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 40px;
    width: max-content;
  }

  &__item {
    flex-shrink: 0;
  }

  @include md {

    &__content {
      gap: 60px;
    }
  }

  @include xl {

    &::before,
    &::after {
      width: calc(50% - 100px);
    }

    &__content {
      gap: 80px;
    }
  }

  @include xxl {

    &::before,
    &::after {
      width: calc(50% - 120px);
    }
  }
}

.brand-item {
  padding-bottom: 15px;
  @include centering;

  &_soon {
    position: relative;
    
    &::after {
      content: 'скоро';
      position: absolute;
      bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      color: #C7C7C7;
    }

    img {
      filter: grayscale(1);
    }
  }

  @include xl {

    &_soon {
    
      &::after {
        font-size: 14px;
      } 
    }
  }
}

.features {

}