.page-footer {
  padding: 40px 0;

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
  }

  @include xl {
    padding: 100px 0;
    
    &__container {
      gap: 80px;
    }
  }
}

.footer-menu {
  $row-gap: 60px;
  $column-gap: 40px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $row-gap $column-gap;

  &__group {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
  }

  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--paragraph);
  }

  @include md {
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;

    &__group {

      &_socials {
        position: absolute;
        top: calc(100% + #{$row-gap});
        right: 0;
        flex-direction: row;
      }
    }
  }

  @include xl {
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: 80px;

    &__group {

      &_socials {
        position: relative;
        top: auto;
        right: auto;
      }
    }

    &__link {
      font-size: 17px;
    }
  }
}

.footer-copyright {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  font-size: 14px;
  line-height: 150%;
  color: #828EA1;

  &__link {
    text-decoration-line: underline;
    color: #3D58A6;
  }

  &__dev {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
    opacity: 0.5;
    font-size: 15px;
    line-height: 150%;
    color: #002288;

    span {
      text-decoration: underline;
    }
  }

  @include xl {
    flex-direction: row;
    justify-content: space-between;
  }
}