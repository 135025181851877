.card {
  display: flex;
  padding: 20px;
  flex-grow: 1;
  border-radius: 15px;
  background-color: var(--block);
  @include transition(background-color);

  text-align: center;

  &__content {
    width: 240px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 15px;
  }

  &__title {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 5px;
  }

  &__icon {
    width: 28px;
  }

  &__btn {
    width: fit-content;
  }

  @include md {
    border-radius: 50px;
  }

  @include xl {

    &_merge {

    }

    &__content {
      width: 480px;
    }

    &__title {
      gap: 20px;
    }

    &__icon {
      width: 48px;
    }
  }
}

.merged-cards {
  display: flex;
  flex-flow: column nowrap;
  gap: 25px;

  @include md {
    flex-direction: row;
    gap: 20px;
  }

  @include xl {
    padding: 64px 30px 68px;
    gap: 0;
    background-color: var(--block);
    @include transition(background-color);
    border-radius: 50px;
    align-items: stretch;

    .card {
      padding: 0;
      border-radius: 0;
      align-items: center;

      &:not(:last-of-type) {
        border-right: 1px solid rgba(54, 106, 243, 0.25);
      }
    }
  }
}