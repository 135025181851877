*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input,
button,
textarea,
fieldset {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

button,
input[type="submit"],
label {
  cursor: pointer;
}

table {
  border-spacing: 0;
}