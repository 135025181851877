// Colors
$theme-colors: (
  "released": #366AF3,
  "hovered": #689CFF,
  "pressed": #184CD5,

  "paragraph": rgba(34, 34, 34, 0.75),
  "link": #366AF3,
  "head-black": #222222,
  "head-blue": #366AF3,
  "head-blue-secondary": #9AB4F9,
  

  "background": #FFFFFF,
  "element": #EDF2FE,
  "block": #ECF1FD,

  "input": #FFFFFF,
  "placeholder": rgba(17, 34, 68, 0.5),
  "value": #112244,

  "error": #FF6D6D,

  "overlay-background": rgba(0, 0, 0, 0.65),
  
);

$dark-theme-colors: (

);


// Breakpoints
$sm-breakpoint: 414px;
$md-breakpoint: 640px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1280px;
$xxl-breakpoint: 1400px;

$breakpoints: (
  "sm-breakpoint": $sm-breakpoint,
  "md-breakpoint": $md-breakpoint,
  "lg-breakpoint": $lg-breakpoint,
  "xl-breakpoint": $xl-breakpoint,
  "xxl-breakpoint": $xxl-breakpoint
);


// Layout
$container-max-width: 1500px;

$container-padding: 20px;
$container-padding-xl: 50px;

$column-gap: 20px;
$column-gap-xl: 40px;


// Components
$border-radius: 5px;
$page-header-height: 74px;

$input-height: 75px;

$modal-bottom-offset: -20px;



// Typography
$font-family-primary: 'Golos Text VF', sans-serif;

$font-size-base: 14px;
$font-size-sm: 12px;
$font-size-xl: 18px;

$font-weight-base: 400;

$line-height-base: 1.65;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: 36px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 22px;
$h5-font-size: 18px;
$h6-font-size: $font-size-base;

$h1-font-size-xl: 76px;
$h2-font-size-xl: 56px;
$h3-font-size-xl: 42px;
$h4-font-size-xl: 32px;
$h5-font-size-xl: 24px;
$h6-font-size-xl: $font-size-xl;

$h1-line-height: 1.05;
$h2-line-height: 1.05;
$h3-line-height: 1.05;
$h4-line-height: 1.10;
$h5-line-height: 1.15;
$h6-line-height: 1.15;

$h1-line-height-xl: 1.05;
$h2-line-height-xl: 1.05;
$h3-line-height-xl: 1.05;
$h4-line-height-xl: 1.10;
$h5-line-height-xl: 1.15;
$h6-line-height-xl: 1.15;


// Z-index list
$zindex-sticky: 1000;
$zindex-fixed: 1010;
$zindex-overlay: 1020;
$zindex-dropdown: 1030;
$zindex-modal: 1040;
$zindex-popover: 1050;
$zindex-tooltip: 1060;