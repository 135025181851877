.btn {
  @include centering;
  height: 75px;
  padding: 0 48px;
  border-radius: $border-radius;
  background-color: var(--released);
  @include transition(background-color);

  font-size: 17px;
  font-weight: 500;
  color: #FFFFFF;

  @include hocus {
    background-color: var(--hovered);
  }

  &:active {
    background-color: var(--pressed);
  }

  &_header {
    height: 50px;
    padding: 0 45px;
    font-size: 18px;
    font-weight: 400;
  }

  &_outline {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: transparent;
    color: rgba(34, 34, 34, 0.75);

    @include dark {
      color: #FFFFFF;
    }
  }

  
}