.popover {
  visibility: hidden;
  opacity: 0;
  width: 220px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(31, 49, 96, 0.08);
  @include transition;
  transition-property: background-color, opacity, visibility;

  &[data-show] {
    visibility: visible;
    opacity: 1;
  }

  &[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }
}