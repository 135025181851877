.modal {
  display: none;
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  bottom: $modal-bottom-offset;
  
  border-radius: 15px 15px 0px 0px;
  background-color: var(--background);
  @include transition(background-color);

  z-index: $zindex-modal;

  &.open {
    display: flex;
    animation: modalOpen .8s;
  }

  &__close-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 100%;
    @include centering;

    color: #FFFFFF;
  }

  &__content {
    width: 100%;
    padding: 40px $container-padding 40px - $modal-bottom-offset;
    overflow: auto;
  }

  &__form {
    display: none;
    @include transition(all);
    max-width: 460px;
    margin: 0 auto;

    &.show {
      display: flex;
      animation: fade-in cubic-bezier(0.215, 0.61, 0.355, 1) .7s;
    }
  }
  
  @include md {
    top: 40px;

    &__content {
      padding: 30px $container-padding 30px - $modal-bottom-offset;
    }
  }
}

@keyframes modalOpen {
  0%, 75%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    transform: translate3d(0,3000px,0)
  }
  75% {
    transform: translate3d(0,$modal-bottom-offset,0)
  }
  to {
    transform: translateZ(0)
  }
}
