.loader {
  position: absolute;
  width: 36px;
  height: 36px;
  display: flex;
  border: 5px solid #FFF;
  border-bottom-color: var(--link);
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}