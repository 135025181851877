@font-face {
  font-family: 'Golos Text VF';
  src: url('../fonts/golos-text_regular.woff2') format('woff2'),
      url('../fonts/golos-text_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text VF';
  src: url('../fonts/golos-text_medium.woff2') format('woff2'),
      url('../fonts/golos-text_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos Text VF';
  src: url('../fonts/golos-text_demibold.woff2') format('woff2'),
      url('../fonts/golos-text_demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Set up Golos Text VF for modern browsers, all weights */
@supports (font-variation-settings: normal) {
  @font-face {
  	font-family: 'Golos Text VF';
  	src: 
      url('../fonts/golos-text_VF.woff2') format('woff2 supports variations'),
      url('../fonts/golos-text_VF.woff2') format('woff2-variations'),
      url('../fonts/golos-text_VF.woff2') format('woff2'),
      url('../fonts/golos-text_VF.woff') format('woff supports variations'),
      url('../fonts/golos-text_VF.woff') format('woff-variations'),
      url('../fonts/golos-text_VF.woff') format('woff');
  	font-weight: 400 900;
  }
}