.main-menu {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;

  &__link {
    padding: 15px;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: var(--paragraph);
    @include transition(color);

    @include hocus {
      color: var(--link);
    }
  }
}

.menu-btn {
  width: 48px;
  height: 48px;
  @include centering;
  border-radius: 50%;
  background-color: var(--element);
  color: #45474B;
  @include transition;
  transition-property: color, background-color;

  @include dark {
    color: #FFFFFF;
  }
}

.page-header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--background);
  @include transition(background-color);
  
  z-index: $zindex-fixed;

  &.fixed {

  }

  &__container {
    max-width: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $page-header-height;
    position: relative;
    z-index: $zindex-fixed + 1;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 20px;
  }

  &__main-menu {
    display: none;
  }

  &__btn {
    display: none;
  }

  @include md {

    &__btn {
      display: flex;
    }
  }

  @include xl {

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__main-menu {
      display: flex;
      justify-content: center;
    }

    &__buttons {
      justify-content: flex-end;
    }

    &__menu-btn {
      display: none;
    }
  }
}

.logo {
  width: 115px;
  margin-right: auto;
  @include centering;

  color: #113355;
  @include transition(color);

  @include dark {
    color: #FFFFFF;
  }
  
  svg {
    width: 100%;
  }
}

.profile-btn {
  @include centering;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: transparent;
  @include transition(background-color);

  color: #366AF3;

  @include hocus {
    background-color: var(--element);
  }

  @include dark {
    color: #FFFFFF;
  }
}

.profile-menu-list {
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 0;
  gap: 8px;
  border-bottom: 1px solid #E7E9EC;

  &:last-of-type {
    border-bottom: none;
  }
}

.profile-menu-item {
  $line-height: 16px;

  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  padding: 12px 20px;

  font-size: 13px;
  line-height: $line-height;
  color: var(--paragraph);

  background-color: transparent;
  @include transition(background-color);

  &__icon {
    height: $line-height;

    svg {
      height: 100%;
    }
  }

  &__caption {
    font-weight: 444;
  }

  @include hocus {
    background-color: var(--primary-100);
  }
}

.mobile-menu {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 0 $container-padding;
  background-color: var(--background);
  
  transform: translateX(100%);
  @include transition;
  transition-property: transform, background-color;

  z-index: $zindex-overlay + 1;

  &.open { 
    transform: translateX(0);
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: $page-header-height;
    background-color: var(--background);
    @include transition(background-color);
  }

  &__menu-btn {
    color: #366AF3;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    overflow: auto;
    padding: 10px 0 40px;
    gap: 40px;
  }

  &__main-menu {
    margin-left: -15px;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;

    @include dark {
      color: #FFFFFF;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
  }

  &__profile-menu {
    margin-top: auto;
    margin-left: -$container-padding;
    padding: 0;
  }

  @include md {
    width: 320px;
    right: 0;
  }
}