.form {
  $gap: 20px;

  &__form,
  &__fieldset,
  &__heading {
    display: flex;
    flex-flow: column nowrap;
    gap: $gap;
  }

  &__heading {
    gap: 10px;
  }

  &__fieldset {
    gap: 15px;
  }

  &__group {

  }

  &__input {
    width: 100%;
    height: $input-height;
    padding: 0 25px;
    border: 1px solid rgba(17, 34, 68, 0.5);
    border-radius: $border-radius;
    background-color: var(--input);

    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: var(--value);

    &_textarea {
      height: 136px;
      padding-top: 18px;
      resize: none;
    }

    &_single {
      text-align: center;
      font-weight: 500;
      font-size: 24px;
    }

    &::placeholder {
      color: var(--placeholder);
    }
  }

  &__code-input {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: var(--head-black);
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
    color: var(--paragraph);
  }

  &__message {
    margin: -$gap 0;
    padding: 10px 28px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @include transition;
    transform: scaleY(0);

    &.show {
      margin: 0; 
      transform: scaleY(1);
    }

    &_error {
      background: rgba(255, 109, 109, 0.25);
      border: 1px dashed #FF6D6D;
      color: #FF6D6D;
    }

    &_success {
      background: rgba(255, 109, 109, 0.25);
      border: 1px dashed #FF6D6D;
      color: #FF6D6D;
    }
  }

  &__agreement {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: var(--paragraph);

    a {
      color: #909090;
    }
  }

  &__btn {
  }

  &__password-recovery {
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 165%;
  }

  @include md {

    &__heading {
      gap: 15px;
    }

    &__title {
      font-size: 24px;
    }

    &__input {
      width: 100%;
      height: 75px;
      padding: 0 25px;
      border: 1px solid rgba(17, 34, 68, 0.5);
      border-radius: $border-radius;
      background-color: var(--input);
  
      font-weight: 400;
      font-size: 17px;
      line-height: 150%;
      color: var(--value);
  
      &_textarea {
        height: 136px;
        padding-top: 18px;
        resize: none;
      }
  
      &_single {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
      }
  
      &::placeholder {
        color: var(--placeholder);
      }
    }

    &__text {
      font-size: 16px;
    }

    &__agreement {
      font-size: 13px;
    }
  }
}