body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: var(--paragraph);
}

strong {
  font-weight: 700;
}

small {
  font-size: $font-size-sm;
}

h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: 600;
  line-height: $h1-line-height;

  @include xl {
    font-size: $h1-font-size-xl;
    line-height: $h1-line-height-xl;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  font-weight: 600;
  line-height: $h2-line-height;

  @include xl {
    font-size: $h2-font-size-xl;
    line-height: $h2-line-height-xl;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  font-weight: 600;
  line-height: $h3-line-height;

  @include xl {
    font-size: $h3-font-size-xl;
    line-height: $h3-line-height-xl;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  font-weight: 600;
  line-height: $h4-line-height;

  @include xl {
    font-size: $h4-font-size-xl;
    line-height: $h4-line-height-xl;
  }
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: 600;
  line-height: $h5-line-height;

  @include xl {
    font-size: $h5-font-size-xl;
    line-height: $h5-line-height-xl;
  }
}

h6,
.h6 {
  font-size: $h6-font-size;
  font-weight: 600;
  line-height: $h6-line-height;

  @include xl {
    font-size: $h6-font-size-xl;
    line-height: $h6-line-height-xl;
  }
}

table {
  overflow: auto;
  display: grid;

  tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    gap: 15px;
  }

  thead tr {
    border-bottom: none;
  }

  th,
  td {
    padding: 10px;
    width: 120px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-align: left;

    &:first-of-type {
      width: 160px;
    }
  }

  th {
    color: var(--primary);
  }

  @include xl {

    tr {
      width: calc(100vw - #{$container-padding-xl} * 2);
      max-width: $container-max-width - $container-padding-xl * 2;
    }

    th,
    td { 
      font-size: 18px;
      line-height: 22px;

      &:nth-of-type(-n+2) {
        width: 220px;
      }
    }
  }
}

.link {
  color: var(--link);
  text-decoration: underline;
  @include transition(color);

  @include hocus {
    color: var(--hovered);
  }
}

.paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 165%;
  color: var(--paragraph);
  opacity: 0.75;

  @include xl {
    font-size: 18px;
  }
}

.common-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 110%;
  color: var(--head-blue);

  @include xl {
    font-weight: 400;
    font-size: 38px;
    line-height: 120%;
  }
}

.text {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6  {
    padding-top: 15px;

    &:first-child {
      padding-top: 0;
    }
  }

  i, em, .italic {
    font-variation-settings: 'ital' 1;
  }

  a {
    @extend .link;
  }

  ul, ol {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    padding-left: 15px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: square;

    li::marker {
      color: var(--primary);
      font-size: 1.18em;
    }
  }

  @include md {
    max-width: 460px;

    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6  {
      padding-top: 30px;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  @include xl {
    max-width: 800px;
    
    ul, ol {
      padding-left: 0;
    }
  }
}