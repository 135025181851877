// Media Queries

// Small devices
@mixin sm {
  @media screen and (min-width: $sm-breakpoint) {
    @content;
  }
}
@mixin sm-down {
  @media screen and (max-width: ($sm-breakpoint - 0.02px)) {
    @content;
  }
}
@mixin sm-only {
  @media screen and (max-width: ($sm-breakpoint - 0.02px)) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media screen and (min-width: $md-breakpoint) {
    @content;
  }
}
@mixin md-down {
  @media screen and (max-width: ($md-breakpoint - 0.02px)) {
    @content;
  }
}
@mixin md-only {
  @media screen and (min-width: $md-breakpoint) and (max-width: ($lg-breakpoint - 0.02px)) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media screen and (min-width: $lg-breakpoint) {
    @content;
  }
}
@mixin lg-down {
  @media screen and (max-width: ($lg-breakpoint - 0.02px)) {
    @content;
  }
}
@mixin lg-only {
  @media screen and (min-width: $lg-breakpoint) and (max-width: ($xl-breakpoint - 0.02px)) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media screen and (min-width: $xl-breakpoint) {
    @content;
  }
}
@mixin xl-down {
  @media screen and (max-width: ($xl-breakpoint - 0.02px)) {
    @content;
  }
}
@mixin xl-only {
  @media screen and (min-width: $xl-breakpoint) and (max-width: ($xxl-breakpoint - 0.02px)) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media screen and (min-width: $xxl-breakpoint) {
    @content;
  }
}
@mixin xxl-down {
  @media screen and (max-width: ($xxl-breakpoint - 0.02px)) {
    @content;
  }
}
@mixin xxl-only {
  @media screen and (min-width: $xxl-breakpoint) {
    @content;
  }
}

// Custom breakpoint
@mixin media($from) {
  @media screen and (min-width: $from) {
    @content;
  }
}
@mixin media-down($to) {
  @media screen and (max-width: ($to - 0.02px)) {
    @content;
  }
}
@mixin media-only($from, $to) {
  @media screen and (min-width: $from) and (max-width: ($to - 0.02px)) {
    @content;
  }
}

// Centrize block
@mixin center($position) {
  position: absolute;
  
  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Children centering
@mixin centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Child block stretching
@mixin absolute-stretch($position: absolute) {
  position: $position;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

// Hover, focus
@mixin hocus {
  &:hover,
  &:focus {
    @content;
  }
}

// Dark
@mixin dark {
  html.dark & {
    @content;
  }
}

// Ratio
@mixin ratio($width, $height) {
  padding-top: calc( $height / $width * 100%);
  position: relative;

  > :first-child {
    @include absolute-stretch;
    object-fit: cover;
    max-width: none;
    height: 100%;
    width: 100%;
  }
}

// Transition
$transition-timing: cubic-bezier(0.215, 0.61, 0.355, 1);
@mixin transition($property: all, $duration: 0.2s, $timing: $transition-timing) {
  transition: $property $duration $timing;
}

// Fluid font size
@mixin fluid-fs($min-w, $max-w, $min-fs, $max-fs) {
  $u1: unit($min-w);
  $u2: unit($max-w);
  $u3: unit($min-fs);
  $u4: unit($max-fs);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-fs;

      @media (min-width: $min-w) {
        font-size: calc(
          #{$min-fs} + #{strip-unit($max-fs - $min-fs)} *
            ((100vw - #{$min-w}) / #{strip-unit($max-w - $min-w)})
        );
      }

      @media (min-width: $max-w) {
        font-size: $max-fs;
      }
    }
  }
}